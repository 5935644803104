<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <!--        <el-form-item label="客户单号">-->
        <!--          <el-input v-model="dataForm.customerSelfNo" placeholder="支持模糊查询" clearable></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker v-model="dataForm.takeGoodsTime" type="daterange" align="right" unlink-panels
            range-separator="-" size="small" @change="getDataList" value-format="yyyy-MM-dd" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择"
            @change="getDataList()">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="签收状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option label="已签收" value="1"></el-option>
            <el-option label="未签收" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回单状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.receiptStatus" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已寄出" value="2"></el-option>
            <el-option label="未寄出" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('order:inside:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('order:receivable:update')"
              @click="openWriteOffPopup(1)" :disabled="!selectFeeData.length">签收单核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('order:receivable:update')" @click="batchAudit(2)"
              :disabled="!selectFeeData.length">签收单反核销
            </el-button>
          </div>
        </el-form-item>
        <el-form-item style="text-align: right; float: right;">
          <div class="operationList">
            <el-button v-if="newAuth('create:special')" size="small" @click="operatopnList('createZC')"
              icon="el-icon-plus" type="primary">创建新订单
            </el-button>
            <!-- <el-button v-if="newAuth('create:carpooling')" size="small" @click="operatopnList('createPC')"
              icon="el-icon-plus" type="primary">创建拼车订单
            </el-button> -->
            <!-- <el-button size="small" @click="operatopnList('address')" type="info" :disabled="selectListArr.length ? false : true">地址反查</el-button> -->
            <el-button v-if="newAuth('order:inside:update')" size="small" @click="operatopnList('attention', 'edit')"
              type="danger" :disabled="selectListArr.length ? false : true">维护注意事项 </el-button>
            <el-button v-if="newAuth('order:inside:audit')" size="small" @click="operatopnList('order')" type="success"
              :disabled="orderExamineAble">订单审核</el-button>
            <!--<el-button size="small" @click="operatopnList('orderReturn')" type="warning" :disabled="orderReturnAble">订单退回 </el-button>-->
            <!--<el-button size="small" @click="operatopnList('info')" type="info" :disabled="true">信息补录</el-button>-->
            <!--<el-button size="small" @click="operatopnList('printPaper')" type="info" :disabled="true">打印落货纸</el-button>-->
            <!--<el-button size="small" @click="operatopnList('printsheet')" type="info" :disabled="true">打印派车单</el-button>-->
            <el-button size="small" @click="receiptUpdate(1)" type="danger" :disabled="selectFeeData.length ? false : true">回单撤回</el-button>
            <el-button size="small" @click="receiptUpdate(2)" type="warning" :disabled="selectFeeData.length ? false : true">回单寄出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" :fit="true" border size="small" height="66vh" :row-class-name="tableRowClassName"
        @row-click="rowClick" :row-style="selectedHighlight" :span-method="arraySpanMethod" ref="tableRef"
        v-loading="dataListLoading" style="width: 100%;" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="40" fixed="left" align="center"></el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户联系人" prop="orderInfo.customerContactName" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货时间" prop="orderInfo.takeGoodsDate" width="105" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime }}
          </template>
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="所需车型" width="80" :formatter="dictConvertVehicle" prop="orderInfo.vehicleType"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机车牌" prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货方" prop="factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系人" prop="contactName" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货电话" prop="contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <af-table-column label="注意事项" prop="takeRemark" width="60">
          <template slot-scope="scope">
            <span style="color:blue;cursor:pointer" @click="viewAttention(scope.row)"
              v-if="scope.row.takeRemark">查看</span>
            <span v-else>无</span>
          </template>
        </af-table-column>
        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货联系人" prop="giveAddressInfo.contactName" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货电话" prop="giveAddressInfo.contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="公里数(KM)" prop="orderInfo.distance" :formatter="getDistanceValue" width="80"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="货物尺寸(长/宽/高)" prop="orderGoods.length" width="110" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
          </template>
        </el-table-column>
        <el-table-column label="包装类型" :formatter="manyDictConvert" prop="orderGoods.packType" width="60"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="货物备注" prop="orderGoods.remark" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="orderInfo.isTailstock" :formatter="yesNo" label="是否尾板" width="60"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="105">
        </el-table-column>
        <el-table-column label="客户单号" prop="orderInfo.customerSelfNo" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户联系电话" prop="orderInfo.customerContactPhone" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名" prop="vehiclePlan.driverName" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话" prop="vehiclePlan.driverPhone" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单时间" prop="orderInfo.commitTime" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="回单寄出时间" prop="orderInfo.receiptSendTime" width="120" :show-overflow-tooltip="true" />
        <el-table-column label="回单收件人" prop="orderInfo.receiptAddressee" width="70" :show-overflow-tooltip="true" />
        <af-table-column align="center" label="状态" fixed="right" prop="status" width="65">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">未提交</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="warning">待审核</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="warning">调度中</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small">已调度</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="success">已完成</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">异常结束</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">审核拒绝</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">订单退回</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="info">已取消</el-tag>
            <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
            <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
            <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
            <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
            <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
          </template>
        </af-table-column>
        <el-table-column align="center" label="签收单状态" prop="orderInfo.writeStatus" fixed="right" width="70"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.writeStatus == 1" size="small" type="success">已签收</el-tag>
            <el-tag v-if="scope.row.orderInfo.writeStatus == 2" size="small" type="danger">未签收</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="回单状态" width="60" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.receiptStatus == 2" size="small" type="success">已寄出</el-tag>
            <el-tag v-else size="small" type="danger">未寄出</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="40">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row, 'view')">详情 </el-button>
              <el-button
                v-if="scope.row.orderInfo.status != 8 && scope.row.orderInfo.status != 9 && newAuth('order:inside:update')"
                type="text" size="small" @click="addOrUpdateHandle(scope.row, 'edit')">修改 </el-button>
              <el-button v-if="scope.row.orderInfo.status == 8 && newAuth('order:inside:updateOrderNumber')" type="text"
                size="small" @click="updateOrderNumber(scope.row)">单号编辑</el-button>
              <el-button v-if="newAuth('order:inside:delete')" type="text" size="small"
                @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 订单审核 -->
    <order-to-examine ref="orderToExamine" @refreshDataList="getDataList"></order-to-examine>
    <!-- 信息补录-->
    <info-supplement ref="infoSupplement" @refreshDataList="getDataList"></info-supplement>
    <!-- 订单退回 -->
    <order-return ref="orderReturn" @refreshDataList="getDataList"></order-return>
    <!-- 维护注意事项 -->
    <attention-pop ref="attention" @refreshDataList="getDataList"></attention-pop>
    <!--    &lt;!&ndash;审核弹窗&ndash;&gt;-->
    <!--    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>-->
    <!-- 单号编辑 -->
    <order-number ref="orderNumber" @refreshDataList="getDataList"></order-number>

    <receipt-off-popup ref="receiptOff" @refreshDataList="getDataList"></receipt-off-popup>
  </div>
</template>

<script>
import orderToExamine from './insideOrderPop/orderToExamine-popup'
import infoSupplement from './insideOrderPop/infoSupplement-popup'
import orderReturn from './insideOrderPop/orderReturnPop'
import attentionPop from './insideOrderPop/attentionPop'
import orderNumber from './insideOrderPop/orderNumber'
import qs from 'qs'
import store from '@/store'
import receiptOffPopup from "@/views/modules/costManagement/detail/receipt-off-popup.vue";

export default {
  components: {
    receiptOffPopup,
    orderToExamine,
    infoSupplement,
    orderReturn,
    attentionPop,
    orderNumber
  },
  data() {
    return {
      dataForm: {
        inWarehouseNo: null,
        customerSelfNo: null,
        customerName: null,
        takeGoodsTime: null,
        vehiclePlate: null,
        writeStatus: null,
        status: 1,
        customerIds: [],
        receiptStatus: null
      },
      statusList: [
        { id: 1, name: '待审核' },
        { id: 2, name: '调度中' },
        { id: 3, name: '已调度' },
        { id: 4, name: '已接单' },
        { id: 5, name: '已出车' },
        { id: 6, name: '已到达' },
        { id: 7, name: '已装货' },
        { id: 15, name: '卸货到场' },
        { id: 8, name: '已完成' },
        { id: 9, name: '异常结束' }
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectListArr: [],
      orderExamineAble: true,
      orderReturnAble: true,
      listType: 2,
      selectFeeData: [],
      getIndex: -1
    }
  },
  created() {
    this.setDefaultDate()
  },
  activated() {
    if (!this.newAuth('order:inside:findAll')) { // 不查看所有的时候，判断所属客户
      this.dataForm.customerId = this.currentUser.customerId
      this.dataForm.customerIds = this.currentUser.customerIdList
    }
    this.getDataList()
  },
  computed: {
    currentUser: {
      get() { return this.$store.state.user.currentUser }
    },
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    // dictConvertVehicle(row, column, cellValue) {
    //   // 处理 orderInfo.vehicleType
    //   return `${store.state.common.dictInfoMap[cellValue]} | ${row.orderInfo.vehicleModelName}`;
    // },
    /**
     * 点击表格变色start
     */
    selectedHighlight({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick(row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    setDefaultDate() {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    openWriteOffPopup(writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.orderInfo.writeStatus !== writeStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '签收单核销'
      if (writeStatus === 1) {
        title = '签收单核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/order/orderInfo/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    batchAudit(writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.orderInfo.writeStatus !== writeStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/order/orderInfo/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectListArr = rows
      this.selectFeeData = rows
      let orderExamineFlag = false // 订单审核判断
      let orderReturnFlag = false //  订单退回判断
      rows.forEach(item => {
        // 订单审核需为未提交或待审核的订单
        if (item.orderInfo.status !== 0 && item.orderInfo.status !== 1) {
          orderExamineFlag = true
        }
        // 订单退回状态判断
        if (item.orderInfo.status > 4) {
          orderReturnFlag = true
        }
      })
      if (rows.length) {
        if (orderExamineFlag) {
          this.orderExamineAble = true
        } else {
          this.orderExamineAble = false
        }
        if (orderReturnFlag) {
          this.orderReturnAble = true
        } else {
          this.orderReturnAble = false
        }
      } else {
        this.orderExamineAble = true
        this.orderReturnAble = true
      }
    },
    // 查看注意事项
    viewAttention(row) {
      this.$refs.attention.init(row, 'view')
    },
    // 操作按钮
    operatopnList(type) {
      if (type === 'createPC') {
        // 创建拼车订单
        this.$router.push('/order-create-carpooling')
      } else if (type === 'createZC') {
        this.$router.push('/order-create-special')
        // 创建专车订单
      } else if (type === 'address') {
        // 地址反查
      } else if (type === 'info') {
        // 信息补录
        // this.$refs.orderToExamine.init(this.selectListArr)
      } else if (type === 'orderReturn') {
        // 订单退回
        this.$refs.orderReturn.init(this.selectListArr)
      } else if (type === 'attention') {
        // 维护注意事项
        if (this.selectListArr.length > 1) {
          this.$message.error('注意事项只能单个维护')
          return
        }
        this.$refs.attention.init(this.selectListArr[0], 'edit')
      } else if (type === 'order') {
        // 订单审核
        this.$refs.orderToExamine.init(this.selectListArr)
      } else if (type === 'printPaper') {
        // 打印落货纸
      } else if (type === 'printsheet') {
        // 打印派车单
      }
    },
    // 订单退回
    orderReturn(row) {
      this.$refs.orderReturn.init(row)
    },
    downLoad() {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/order/orderExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': 2,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'status': this.dataForm.status,
          'customerSelfNo': this.dataForm.customerSelfNo,
          'customerName': this.dataForm.customerName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '订单列表(内部).xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'status': this.dataForm.status,
        'customerSelfNo': this.dataForm.customerSelfNo,
        'customerName': this.dataForm.customerName,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'writeStatus': this.dataForm.writeStatus,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'customerIdList': this.dataForm.customerIds,
        'receiptStatus': this.dataForm.receiptStatus
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findInsideList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 详情
    detail(id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          todo: todo,
          listType: this.listType
        }
      })
    },
    // 删除
    deleteHandle(row) {
      this.$confirm(`确定对【${row.orderInfo.orderNo}】进行【删除】操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/delete/' + row.orderInfo.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => { })
    },
    // 单号编辑
    updateOrderNumber(row) {
      this.$refs.orderNumber.init(row)
    },
    getDistanceValue(row, cell, value) {
      return parseFloat((value * 0.001).toFixed(1))
    },
    receiptUpdate (receiptStatus, row) {
      if (receiptStatus === 1) { // 回单撤销
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【回单撤销】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/order/receiptUpdate'),
            method: 'post',
            data: {
              ids: ids,
              receiptStatus: receiptStatus
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (receiptStatus === 2) { // 回单寄出
        //this.receiptOffVisible = true
        let ids = []
        this.selectFeeData.forEach(item => {
          if (item.orderInfo.receiptStatus !== receiptStatus) {
            ids.push(item.orderInfo.id)
          }
        })
        if (ids.length === 0) {
          this.$message.error('没有符合寄出的数据，请检查是否勾选正确')
          return
        }
        this.$refs.receiptOff.init(receiptStatus, ids)
      }
    },
  },
  watch: {
    '$store.state.common.audited': {
      handler: function (val, oldVal) {
        if (val === '新委派') {
          this.dataForm.status = 1
        }
        // if (val) {
        //   this.selectTab({ label: val })
        //   this.tabActive = val
        // } else {
        //   this.selectTab({ label: '全部' })
        //   this.tabActive = '全部'
        // }
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
div#pane-order-list-inside {
  .el-dialog__header {
    padding: 10px;
    padding-top: 20px
  }

  .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 5px 25px;
  }

  .el-dialog__footer {
    padding: 0px 20px 10px;
  }

  .searchBox {
    height: 76px
  }
}
</style>
